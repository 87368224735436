import html from "../../../assets/html5.png"
import css from "../../../assets/css3.png"
import js from "../../../assets/js.png"
import cpp from "../../../assets/cpp.png"
import ruby from "../../../assets/ruby.png"
import php from "../../../assets/php.png"
import rails from "../../../assets/rails.png"
import react from "../../../assets/react.png"
import redux from "../../../assets/redux.png"
import git from "../../../assets/git.png"
import useBoundingClientRect from "../../../functions/useBoundingClientRect"
import Cell from "./cell"
import Modal from "./modal"
import { useState, useRef } from "react"
import Highlight from "react-highlight"
import NewTab from "../../newTab"
import languages from "../../../constants/languages"
import IntersectionObserved from "../../intersectionObserved"

export default function Languages () {
    const ref = useRef(null);
    const ref2 = useRef(null);
    const computeTransform = (rect) => {
        return ("rotateY(180deg)")
    }
    const disableCss = () =>{
        const sheets = document.styleSheets;
        for (let i = 0; i < sheets.length; i++) {
            sheets[i].disabled = true;
            
        }
        setTimeout(function(){
            for (let i = 0; i < sheets.length; i++) {
                sheets[i].disabled = false;
                
            }
       }, 2000);//wait 2 seconds
    }
    const [modalContent, setModalContent] = useState("");
    const cb = (b) => {
        if(!b){
            setModalContent("")
        }
    }
    useBoundingClientRect(ref, computeTransform)
    return(
        
            <IntersectionObserved callback={cb} className="languages">
                <div className="title">
                    Voici ce que je maîtrise : 
                </div>
            <div className={`honneycomb`} ref={ref}style={{
                transform: modalContent && computeTransform()
            }}>
                <div className="line">
                    <Cell img={html} text="HTML" callback={setModalContent}>
                        Le HTML, ou HyperText Markup Language est le Language qui permet de structurer la page web.
                        <Highlight language="html">
                            {
                                languages.html
                            }
                        </Highlight>
                    </Cell>
                    <Cell img={cpp} text="C++" callback={setModalContent}> 
                        Le C++ est un Language <NewTab href="https://fr.wikipedia.org/wiki/Langage_de_programmation_de_bas_niveau">bas niveau</NewTab> qui pemet notament de faire de la robotique ou de l'<NewTab href="https://fr.wikipedia.org/wiki/Internet_des_objets">IOT</NewTab>.
                        <Highlight language="c">
                            {
                                languages.c
                            }
                        </Highlight>
                    </Cell>
                    <Cell img={rails} text="Rails" title="Ruby on rails" callback={setModalContent}>
                        Ruby on Rails est un <NewTab href="https://fr.wikipedia.org/wiki/Framework">framework</NewTab> <NewTab href="https://www.wildcodeschool.com/fr-FR/blog/differences-backend-frontend-developpement-web">Back-end</NewTab>
                        <Highlight language="ruby">
                            {
                                languages.rails
                            }
                        </Highlight>
                    </Cell>
                </div>
                <div className="line">
                    <Cell img={css} text="CSS" callback={setModalContent}>
                        le CSS, ou Feuilles de style en cascade permet d'ajouter du style a une page web. <span onClick={disableCss}> <u>Desactiver le css de cette page pendant deux secondes</u></span>
                        <Highlight language="css">
                            {
                                languages.css
                            }
                        </Highlight>
                    </Cell>
                    <Cell img={ruby} text="Ruby" callback={setModalContent}>
                        Ruby est un langage de programation <NewTab href="http://www.france-ioi.org/algo/course.php?idChapter=561&idCourse=2368">interprété</NewTab>
                        <Highlight language="ruby">
                            {
                                languages.ruby
                            }
                        </Highlight>
                    </Cell>
                    <Cell img={react} text="React" callback={setModalContent}>
                        React est une librairie <NewTab href="https://www.wildcodeschool.com/fr-FR/blog/differences-backend-frontend-developpement-web">Front-end</NewTab>
                
                        <Highlight language="javascript">
                            {
                                languages.react
                            }
                        </Highlight>
                    </Cell>
                    <Cell img={git} text="Git" callback={setModalContent}>
                        Git est un logiciel de gestion de versions
                    </Cell>
                </div>
                <div className="line">
                    <Cell img={js} text="JS" title="JavaScript" callback={setModalContent}>
                        JavaScript est un langage de programation <NewTab href="http://www.france-ioi.org/algo/course.php?idChapter=561&idCourse=2368">interprété</NewTab> chargé de dynamiser les pages web (nous ne pouvons pas le desactiver ici, l'application entiere est rendu grace a JavaScript).
                        <Highlight language="javascript">
                            {
                                languages.js
                            }
                        </Highlight>
                    </Cell>
                    <Cell img={php} text="PHP" callback={setModalContent}>
                        PHP est un langage de programation <NewTab href="http://www.france-ioi.org/algo/course.php?idChapter=561&idCourse=2368">interprété</NewTab> généralement utilisé pour faire du <NewTab href="https://blog.nicolas.brondin-bernard.com/qu-est-ce-que-le-ssr-ou-server-side-rendering/"> SSR (Server Side Rendering : rendu cote serveur)</NewTab>.
                        <Highlight language="php">
                            {
                                languages.php
                            }
                        </Highlight>
                    </Cell>
                    <Cell img={redux} text="Redux" callback={setModalContent}>
                        Redux est un conteneur d'état d'application.
                </Cell>
                </div>
            </div>
            {
                
                <Modal cell={modalContent} ref={ref2} callback={setModalContent}/>
            }
        </IntersectionObserved>
    )
}