import IntersectionObserved from "../../../intersectionObserved";
import { useState, useRef } from "react";
import vh from "../../../../functions/vh"
export default function Cell (props) {
    const cell = useRef(null);
    const [inview, setInview] = useState(false);
    const setModal = () => {
        props.callback({
            title : (props.title ? props.title : props.text),
            img : props.img,
            text : props.children,
            cell : cell
        })
    }
    return(
        <IntersectionObserved rootMargin={vh(-25)} callback={setInview}>
                <div className={`cell ${inview && "in" || ""}`} onClick={setModal} ref={cell}>
                    <div className="content">
                        <div className="img">
                            <img src={props.img} alt="" />
                        </div>
                        <div className="text">
                            {props.text}
                        </div>
                    </div>
                </div>
        </IntersectionObserved>
    )
}