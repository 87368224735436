import {ref, useEffect} from "react";
import { IntersectionObserverProps, useInView } from "react-intersection-observer";

export default function IntersectionObserved(props){
    const {ref, inView, entry} = useInView({
        threshold : props.threshold,
        rootMargin : `${props.rootMargin ? props.rootMargin : "0px"} 0px`
    })
    useEffect(
        ()=>{
            props.callback(inView);
        },
        [inView]
    )
    return (
        <div ref={ref} className={props.className}>
            {props.children}
        </div>
    )
}