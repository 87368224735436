export default function Outline(props){
    const buildGradient = () => {
        if (props.gradient) {
            const length = props.gradient.length;
            const turnStop = 360/length;
            const stops = props.gradient.map((color, i) => (
                `${color} ${i*turnStop-(i == 0 ? 0 : turnStop/2)}deg`
            ));
            stops.push(`${props.gradient[0]} 360deg`);
            return(`conic-gradient(${stops.join(", ")})`);
        }
    }
    
    return(
        <div className="outline"
        style={{
            width: `calc(100% + ${props.strokeWidth})`,
            height: `calc(100% + ${props.strokeWidth})`,
            top: `calc(0% - ${props.strokeWidth}/2)`,
            left: `calc(0% - ${props.strokeWidth}/2)`,
            background: buildGradient()
        }}>
        </div>
    )
}