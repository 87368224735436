import Tile from "./tile"
import { useEffect, useRef, useState } from "react"
import NavIcon from "./navIcon"
import Player from "./player"
import IntersectionObserved from "../intersectionObserved"
export default function SoftSkills(props){
    const length = props.children.length;
    const [maxHeight, setMaxHeight] = useState(0);
    const [index, setIndex] = useState(0);
    const [paused, setPaused] = useState(false);
    const pauseRef = useRef(paused);
    pauseRef.current = paused;
    const [inview, setInview] = useState(false);
    const togglePause = () =>{
        if(paused){
            setIndex(index+1)
        }
        setPaused(!paused)
    }
    const prev = () =>{
        setPaused(true);
        setIndex(index => Math.abs(index) -1)
    }
    const next = () =>{
        setPaused(true);
        setIndex(index => index+1)
    }
    const computeMaxHeight = (height) => {
        if(height > maxHeight){
            setMaxHeight(height);
        }
    }
    const handleKeyPress = (e) =>{
        switch (e.key) {
            case " ":
                e.preventDefault();
                setPaused(paused => !paused);
                if(!pauseRef.current){
                    setIndex(index => index + 1)
                }
                break;
            case "ArrowRight":
                e.preventDefault();
                next();
                break;
            case "ArrowLeft":
                e.preventDefault();
                prev();
                break;
            default:
                break;
        }
    }
    useEffect(()=>{
        const interval = setInterval(()=>{
            if(!pauseRef.current){
                setIndex(index => ((index + 1)))
            }
            return () => {
                clearInterval(interval);
            }
        }, 5000)
    }, [])
    useEffect(()=>{
        window.addEventListener("keydown", handleKeyPress)
        if(!inview){
            window.removeEventListener("keydown", handleKeyPress);
        }
        return ()=>{
            window.removeEventListener("keydown", handleKeyPress);
        }
    }, [inview])
    return(
        <IntersectionObserved className="soft-skills-container" callback={setInview}>
            <div className="soft-skills" style={{
                height : `${maxHeight}px`
                }}>
                {    
                    props.children.map((tile, i) => (
                        <Tile icon={tile.icon} heightCallback={computeMaxHeight} height={maxHeight} index={i} current={index} length={length}>
                            <span>
                                <div className="title">
                                    <h2>
                                    {   tile.title}
                                    </h2>
                                </div>
                                {tile.text}
                            </span>
                        </Tile>
                    ))
                }
            </div>
            <div className="nav">

                {
                props.children.map((tile, i) =>(
                    <NavIcon index={i} icon={tile.icon} callback={()=>{}} current={index} length={length} setCurrent={setIndex}/>
                ))
                }
                <Player prev={prev} next={next} paused={paused} setPaused={togglePause}/>
            </div>
        </IntersectionObserved>
    )
}