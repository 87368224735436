import vs from "../assets/icons/vs.png"
import ai from "../assets/icons/ai.png"
import ps from "../assets/icons/ps.png"
import bl from "../assets/icons/blender.png"
import fi from "../assets/icons/figma.svg"
import xd from "../assets/icons/xd.png"
import f360 from "../assets/icons/f360.png"

const softs = [
    {
        img : vs,
        title : "Visual Studio Code",
        content : "c'est VS code mdr"
    },{
        img : ai,
        title : "Adobe Illustrator",
        content : "c'est illustrator mdr"
    },{
        img : ps,
        title : "Adobe Photoshop",
        content : "c'est photoshop mdr"
    },{
        img : xd,
        title : "Adobe XD",
        content : "c'est XD mdr"
    },{
        img : fi,
        title : "Figma",
        content : "c'est Figma mdr"
    },{
        img : f360,
        title : "Fusion 360",
        content : "c'est Fusion360 mdr"
    },{
        img : bl,
        title : "Blender",
        content : "c'est Blender mdr"
    }
]


export default softs;