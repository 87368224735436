import IntersectionObserved from "../../intersectionObserved"
import vh from "../../../functions/vh"
import { useState, useEffect } from "react"
import isMobile from "../../../functions/isMobile"
const CountDownText = (props) => {
    const [inView, setInview] = useState(false);
    const [save, setSave] = useState(false);
    const rootMargin = props.rootMargin ? props.rootMargin : (isMobile() ? vh(-10) : vh(-30))
    useEffect(()=>{
        if(inView){
            setSave(true);
        }
    }, [inView]);
    const parseWords = () =>{
        return props.children.split(" ").map(word => (
            <span>
                {word}
            </span>
        ))
    }
    return(
        <IntersectionObserved rootMargin={rootMargin} callback={setInview}>
            <div className={`content countdown ${inView && "in"}`}>
                <div className={`cd ${save && "set"}`}>
                    {parseWords()}
                </div>
            </div>
        </IntersectionObserved>
    )
}

export default CountDownText;