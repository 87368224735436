const outlineGradients = [
        [
            "#D97E96",
            "#0388A6",
            "#B4F2A2",
            "#BF9E39",
            "#D96704"
        ],
        [
            "#47C574",
            "#D47D0A",
            "#F2CA50",
            "#AE5062",
            "#E1B204"
        ]
]

export default outlineGradients