import Index from './partials/index/index';
import Mouse from './components/mouse';
import Nav from './components/nav';
import Cv from './partials/cv';
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { createBrowserHistory } from 'history';
function App(props) {
  const history = createBrowserHistory();
  console.log(props.errors);
  if(props.errors.error === "404"){
    switch (props.errors.requestedUri) {
      case "/cv":
        history.push("/cv")
        break;
      default:
        break;
    }
  }
  return (
    <Router history={history}>
      <Routes>
        {}
        <Route path="/cv" element={<Cv />}/>
        <Route path="/" element={<Index />}/>
      </Routes>
  </Router>
);
}
export default App;

