import { useState, useLayoutEffect, useCallback } from "react";
import vh from "./vh";

const useAnimationRatio = (ref, threshold = 0, animationRatioCallback = null) => {
    const [current, setCurrent] = useState(ref.current)
    const [progress, setProgress] = useState(null);
    const refreshProgress = (ratio) => {
        animationRatioCallback && animationRatioCallback(ratio);
        setProgress(ratio)
    }
    const eventCallback = ()=>{
        if(current){
            const wvh = vh(100, false);
            const offset = (wvh + (current.getBoundingClientRect().top) + threshold) / wvh;
            if(offset > 0 && offset < 1){
                refreshProgress(offset);
            } else if(offset > 1){
                refreshProgress(1)
            } else {
                refreshProgress(0);
            }
        }
    }
    useLayoutEffect(()=>{
        setCurrent(ref.current)
        if(current){
            window.addEventListener('scroll', eventCallback )
        }
        return ()=>{
            window.removeEventListener('scroll', eventCallback)
        }
    },[ref.current])
   return [progress, current]
}

export default useAnimationRatio;