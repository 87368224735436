import { useEffect, useState, useRef } from "react";
import vh from "../../../functions/vh";
import vw from "../../../functions/vw";
import useEventListener from "../../../functions/useEventListener";
import Legend from "./legend";
export default function Lengends (props) {
    const ref = useRef(null);
    const generateLegends = () => {
        return(
            props.legends.map(legend => (
                <Legend {...legend} lengendRef={ref}/>
            ))
        )
    }
    const [legends, setLegends] = useState(generateLegends())
    const [wrapperWidth, setWrapperWidth] = useState(vh(100, false)  * (16 / 9));
    const [wrapperHeight, setWrapperHeight] = useState();
    const computeWrapperWidth = () => {
        if(vw(100, false)/vh(100, false) > 16/9){
            setWrapperWidth(`${vh(100, false)*(16/9)}px`);
            setWrapperHeight("100vh");
            return
        }
        setWrapperHeight(`${vw(100, false)/(16/9)}px`);
        setWrapperWidth("100vw")
        
    }
    useEventListener(window,"resize", ()=>{
        setLegends(generateLegends());
        computeWrapperWidth();
    })
    useEffect(()=>{
        if(!wrapperHeight){
            computeWrapperWidth();
        }
    },[wrapperHeight]);
    return(
        <div className="legends" ref={ref}>
            <div className="legends-container">
                <div className="legends-wrapper" style={{width : wrapperWidth, height : wrapperHeight}} >
                    <div style={
                        {
                            position : "absolute",
                            inset : "0px",
                            pointerEvents :"none"

                        }
                    }>
                        <div style={
                            {
                                width:"100%",
                                height:"100%",
                                position:"relative",
                                pointerEvents:"none"
                            }
                        }>


                    {
                        legends
                    }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

/*
wrapper width logic :
wrapper aspect ratio is set based on width
we need to find the proper width
the image is 16:9 and is contained in screen
so : if the screen is thicker than high :
Sh<Sw
so :
Iw = Sh * (16 / 9)
*/