import { useEffect } from "react"

const useBoundingRectComparaison = (ref1, ref2, callback) =>{
    const executeCallback = () => {
        ref1.current && ref2.current &&
        callback && callback(ref1.current.getBoundingClientRect(), ref2.current.getBoundingClientRect())
    }
    return useEffect(()=>{
        if(ref1.current && ref2.current){
            window.addEventListener("scroll", executeCallback);
        }
        return()=>window.removeEventListener("scroll", executeCallback);
    }, [ref1, ref2])
}

export default useBoundingRectComparaison;