import { useState, useEffect, useMemo } from "react";

const useAnimationRatioCallbacks = (callbacks = []) =>{
    const cbs = callbacks;
    const [ratio, setRatio] = useState(1);
    const [prevRatio, setPrevRatio] = useState(1);
    const [direction, setDirection] = useState(undefined);
    const scanCallbacks = () => {
        cbs.forEach(cb => {
            const dir = cb.params?.direction;
            let exec = false;
            if(direction && (dir === true || dir === undefined)){
                if(cb.triggerRatio <= ratio){
                    exec = true
                }
            } else if(!direction && (dir === false || dir === undefined)){
                if(cb.triggerRatio >= ratio){
                    exec = true
                }
            }
            if (exec) {
                cb.callback && cb.callback();
            }
        })
    }
    useEffect(()=>{
        if(ratio != undefined){
            scanCallbacks();
            setDirection(!(ratio > prevRatio))
            setPrevRatio(ratio)
        }
    }, [ratio])
    return [setRatio, direction]
}

export default useAnimationRatioCallbacks;