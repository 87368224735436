import { useState } from "react";
import toocanIframeContent from "../../../toocanIframeContent"
import Frame from "..";
import vh from "../../../../functions/vh";
const Cursor = (props)=> {
    const cursorHeight = vh(40, false)
    const[mousePos, setMousePos] = useState(["0px","0px"])
    const[cursorSize, setCursorSize] = useState(`${cursorHeight}px`);
    const handleMove = (e) =>{
        e.persist();
        setMousePos([
            `${e.nativeEvent.offsetX}px`,
            `${e.nativeEvent.offsetY}px`
        ])

    }
    const handleTouchStart = (e) =>{
        setMousePos([
            `${e.nativeEvent.changedTouches[0].clientX}px`,
            `${e.nativeEvent.changedTouches[0].clientY}px`
        ])
    }
    const handleEnter = (e) =>{
        setCursorSize(`${cursorHeight}px`);
        handleMove(e);
    }
    const handleLeave = () => {
        setCursorSize("0px");
    }
    return(
        <div className="other" 
        onMouseOver={handleEnter}
        onMouseMove={handleMove}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchStart}
        style={{
            display : props.showFixedElement ? "block" : "none"
        }}
        >
            <div className="planer"  style={{
                mask : `radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 11%, rgba(0,0,0,0) 65%, rgba(0,0,0,0) 65%) ${mousePos[0].replace("px", "")-(cursorSize.replace("px", ""))/2}px  ${mousePos[1].replace("px", "")-(cursorSize.replace("px", ""))/2}px/${cursorSize} ${cursorSize} no-repeat`,
                WebkitMask : `radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 11%, rgba(0,0,0,0) 65%, rgba(0,0,0,0) 65%) ${mousePos[0].replace("px", "")-(cursorSize.replace("px", ""))/2}px  ${mousePos[1].replace("px", "")-(cursorSize.replace("px", ""))/2}px/${cursorSize} ${cursorSize} no-repeat`,
                
            }}>
                <Frame url={toocanIframeContent} iframe></Frame>
            </div>
        </div>
    )
}

export default Cursor;