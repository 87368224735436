/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { Html } from '@react-three/drei'
import { Transform } from 'ink'
import {DoubleSide} from "three"

export default function Laptop(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/laptop.glb')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} visible={props.visible} dispose={null} scale={0.4} rotation={[
      (props.cursorRatio[1]*0.05*Math.PI),
      ((props.rotationFactor)*(-Math.PI/2))+ (props.cursorRatio[0]*0.05*Math.PI),
      ((1-props.rotationFactor)*(Math.PI / 2))
    ]}
    position={[
      0.125, (1-props.rotationFactor)*(-1), 0
    ]}>
      <mesh
        name="Plane002"
        geometry={nodes.Plane002.geometry}
        material={materials['Material.002']}
        position={[0, 0.25, -1.47]}
        rotation={[0, (props.rotationFactor*(-1.55) + (1 - props.secondAnimation)*(-1.55)), (-Math.PI / 2)]}>
      </mesh>


      <mesh
        name="Plane005"
        geometry={nodes.Plane005.geometry}
        material={materials['Material.001']}
        position={[0, 0.25, -1.47]}
        rotation={[0, 0, -Math.PI / 2]}
      />
    </group>
  )
}

useGLTF.preload('/laptop.glb')