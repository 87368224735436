import PlayPause from "./playPause"
import ArrowLeft from "../icons/arrowLeft"
import ArrowRight from "../icons/arrowRight"
export default function Player (props) {
    return(
        <div className="player">
            <div className="item" onClick={props.prev}>
                <ArrowLeft/>
            </div>
            <div className="item">
                <PlayPause paused={props.paused} setPaused={props.setPaused}></PlayPause>
            </div>
            <div className="item" onClick={props.next}>
                <ArrowRight/>
            </div>
        </div>
    )
}