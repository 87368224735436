import { useEffect } from "react"

const useEventListener = (target, key, callback) => {
    return useEffect(()=>{
        target.addEventListener(key, callback);
        return () => {
            target.removeEventListener(key, callback);
        }
    }, [])
}

export default useEventListener;