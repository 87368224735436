export default function Anotation(props){
    return(
        <div className="anotation">
            <p>
                {
                    props.anotation
                }
            </p>
            {
                props.source 
                &&
                <div className="source">
                    {
                        props.source
                    }
                </div>
            }
        </div>
    )
}