import Contact from "../../components/contact"
import Envelope from "../../components/icons/envelope"
import LinkedIn from "../../components/icons/linkedin"
import outlinesGradients from "../../constants/outlinesGradients"

/* PARTIALS SHOULDN'T HAVE PROPS*/

const Contacts = () => {
    return(
        <div className="contacts">
            <Contact icon={<Envelope/>} href="contact@cantoo.dev" mail gradient={outlinesGradients[0]}>
                <span>contact</span><span>@cantoo</span><span>.dev</span>
            </Contact>
            <Contact icon={<LinkedIn/>} href="https://www.linkedin.com/in/boris-marion-dorier-bb2685208/" gradient={outlinesGradients[1]}>
                <span>Boris&nbsp;</span><span>Marion-</span><span>Dorier</span>
            </Contact>
        </div>
    )
}

export default Contacts