import useBoundingRectComparaison from "../../../../functions/useBoundingRectComparaison"
import { useRef, useState } from "react"
import vh from "../../../../functions/vh";
export default function Legend (props) {
    const rm = vh(10, false)
    const [inView, setInView] = useState(false);
    const cb = (client1, client2) =>{
        if(client1.top + rm < client2.top && client1.bottom - rm > client2.bottom ){
            setInView(true)
            return
        }
        setInView(false);
    }
    const ref = useRef(null);
    useBoundingRectComparaison(props.lengendRef, ref, cb)
    return(
        <div className={`legend`} ref={ref} style={
            {
                top : props.top+"%",
                left : props.left+"%"
            }
        }>
            <div className={`legend-content ${props.content?"u":""} ${inView?"in":"n"}`}>
                <p>
                    {props.text}
                </p>
                {
                    props.content
                    &&
                    <div className="content">
                       <span>
                       {props.content}
                       </span>
                    </div>
                }
            </div>
        </div>
    )
}