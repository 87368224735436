import { useState } from "react"
import IntersectionObserved from "../intersectionObserved";
import vh from "../../functions/vh";
const Li = (props) => {
    const [inView, setInView] = useState(false);
    return(
        <li className={`li ${inView && "in" || "out"}`}>
            <IntersectionObserved callback={setInView} rootMargin={vh(-10, true)}>
                {props.children}
            </IntersectionObserved>
        </li>
    )
}

export default Li;