import { useRef, useLayoutEffect } from "react"

export default function Ruler(props){
    const ref = useRef();
    useLayoutEffect(()=>{
        props.domProvider && props.domProvider(ref.current)
    })
    return(
        <svg ref={ref} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512.001 512.001" xmlSpace="preserve">

            <path d="M505.926,0.749c-3.677-1.523-7.907-0.681-10.722,2.133L2.881,495.207c-2.814,2.814-3.654,7.045-2.131,10.72
                        C2.271,509.604,5.86,512,9.838,512h492.325c5.434,0,9.838-4.405,9.838-9.838V9.838C511.999,5.86,509.603,2.272,505.926,0.749z
                        M492.326,492.325h-0.003H33.588l44.393-44.393l15.525,15.524c1.92,1.922,4.439,2.882,6.956,2.882c2.517,0,5.036-0.96,6.957-2.882
                        c3.842-3.842,3.842-10.071-0.001-13.912L91.892,434.02l21.493-21.493l15.524,15.524c1.922,1.92,4.44,2.882,6.957,2.882
                        c2.517,0,5.036-0.96,6.957-2.882c3.842-3.842,3.842-10.071,0-13.912l-15.525-15.525l21.492-21.492l15.525,15.524
                        c1.922,1.92,4.44,2.882,6.957,2.882c2.517,0,5.036-0.96,6.957-2.882c3.842-3.842,3.842-10.071,0-13.912l-15.525-15.526
                        l21.493-21.493l15.524,15.525c1.922,1.922,4.439,2.882,6.957,2.882c2.517,0,5.036-0.96,6.957-2.882
                        c3.842-3.842,3.842-10.07,0-13.912l-15.525-15.525l21.492-21.492l15.524,15.524c1.922,1.922,4.439,2.882,6.957,2.882
                        c2.517,0,5.036-0.96,6.957-2.882c3.842-3.842,3.842-10.07,0-13.912l-15.524-15.525l21.493-21.493l15.524,15.525
                        c1.922,1.922,4.439,2.882,6.957,2.882c2.517,0,5.036-0.96,6.957-2.882c3.842-3.842,3.842-10.07,0-13.912l-15.525-15.525
                        l21.492-21.492l15.524,15.524c1.922,1.922,4.439,2.882,6.957,2.882c2.517,0,5.036-0.96,6.957-2.882
                        c3.842-3.842,3.842-10.07,0-13.912l-15.524-15.525l21.493-21.493l15.524,15.525c1.922,1.922,4.439,2.882,6.957,2.882
                        c2.517,0,5.036-0.96,6.957-2.882c3.842-3.842,3.842-10.07,0-13.912l-15.525-15.525l21.492-21.493l15.524,15.524
                        c1.922,1.922,4.439,2.882,6.957,2.882c2.517,0,5.036-0.96,6.957-2.882c3.842-3.842,3.842-10.07,0-13.912l-15.524-15.525
                        l21.493-21.493l15.524,15.524c1.922,1.92,4.44,2.882,6.957,2.882s5.036-0.96,6.957-2.882c3.842-3.842,3.842-10.071,0-13.912
                        l-15.525-15.525l21.492-21.492l15.524,15.524c1.922,1.92,4.44,2.882,6.957,2.882s5.036-0.96,6.957-2.882
                        c3.842-3.842,3.842-10.071,0-13.912l-15.525-15.525l46.371-46.371V492.325z"/>
            <path d="M422.008,203.348c-3.677-1.523-7.907-0.682-10.722,2.133L205.478,411.289c-2.814,2.814-3.654,7.045-2.132,10.72
                c1.522,3.677,5.11,6.073,9.089,6.073h205.808c5.434,0,9.838-4.405,9.838-9.838V212.437
                C428.081,208.458,425.683,204.871,422.008,203.348z M408.406,408.407H236.185l172.221-172.22V408.407z"/>
        </svg>
    )
}