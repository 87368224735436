import IntersectionObserved from "../intersectionObserved";
import vh from "../../functions/vh";
import win from "../../assets/icons/win10.png"
import lin from "../../assets/icons/ubuntu.png"
import SoftsList from "./softs-list";
import { useState, useRef } from "react";
import useAnimationRatio from "../../functions/useAnimationRatio";
import softs from "../../constants/softs"

export default function Uses () {
    const ref = useRef(null)
    const [firstTitle, setFirstTitle] = useState(false)
    const [progress, key, current] = useAnimationRatio(ref);
    const[n, setN] = useState();

    return(
        <div className="uses">
            <div className="title">
                <span className="text">De Windows
                    et Linux
                </span>
            </div>
            <IntersectionObserved rootMargin={vh(-30)} callback={setFirstTitle}>
                <div className={`os ${firstTitle && "in-view" || ""}`}>
                    <img src={win} alt="" srcset="" />
                    <img src={lin} alt=""/>
                </div>
            </IntersectionObserved>
            <div className="softs" ref={ref}>
                <SoftsList ref={ref} scrollRatio={progress} setN={setN} softs={softs}/>
                <div>
                    <div className="title">
                    </div>
                </div>
            </div>
        </div>
    )
}