import { useEffect, useState, useRef} from "react";
import vw from "../../../../functions/vw";
import vh from "../../../../functions/vh";
export default function SoftsContainer(props) {
    const n = Math.round((props.children.length - 1) * (1 - props.n));
    props.setN(n);
    const [transforms, setTransforms] = useState([])
    const [sliderWidth, setSliderWidth] = useState(0);
    const sideLength = Math.max(...[vw(20, false), vh(20, false)]);
    const ref = useRef(null);
    const [refSize, setRefSize] = useState("0px");
    const setChildrenTransforms = (n = 0) => {
        const full = 180;
        let r = [];
        if(props.children){
            const length = props.children.length;
            const averageRotation = full/length;
            const floor = 0.2;
            for(let i = 0; i < length; i++){
                r.push(<div className="soft" style={{
                    width : sideLength + "px",
                    height : sideLength + "px"
                }}>
                    <img src={props.children[i].props.src} style={transforms[i]}/>
                </div>
                );
            }
        }
        return r;
    }
    useEffect(()=>{
        const full = 360;
        let temp = []
        const length = props.children.length;
        for(let i = 0; i < length; i++){
            const scale = 1-Math.sqrt(Math.abs(i-n)/length);
            temp[i] =
            {
                transform : `scale(${scale})`,
                opacity : scale
            }
        }
        setTransforms(temp);
    }, [props.n])
    useEffect(()=>{
        if(ref.current){
            setRefSize(`${ref.current.offsetHeight}px`);
            setSliderWidth(window.getComputedStyle(ref.current).width.replace("px", ""));
        }
    }, [ref])
    return(
        <div className="softs-container">

<div className="title">
                    et quelques logiciels et outils :
                </div>
            <div className="wrapper-container" style={{height:refSize}}>
                <div className="softs-wrapper">
                    <div className="soft-shifter" style={
                        {
                            transform:`translateX(calc(50% - ${sideLength/2}px))`
                        }
                    }>
                        <div className="softs-slider"style={{
                        transform : `translateX(-${n*sideLength}px)`
                    }}
                    ref={ref}>
                        {
                            setChildrenTransforms()
                        }
                        </div>
                    </div>
                </div>
                <div className="soft-name-container">
                    <div className="soft-name">
                        {props.softs[n].title}
                    </div>
                </div>
            </div>
        </div>
    )
}