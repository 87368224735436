import {useState} from "react";
const Halftone = (props) => {
    return(
        <div className="halftone" style={props.style}>
            <div className={`mask ${props.triggered && "animated"}`} style={{
                background : `radial-gradient(${props.dotsColor}, transparent) 0 0/ 1vh 1vh space`
            }}>

            </div>
        </div>
    )
}

export default Halftone;