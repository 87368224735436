import mouseMoveCoords from "../../../../functions/mouseMoveCoords"
import { useState, useEffect } from "react";

export default function Modal(props) {
    const [y, setY] = useState("translateY(0%)");
    const [trd, setTrd] = useState("0.5s");
    const [content, setContent] = useState("");
    const computeCoordinates = (percentage) => {
        return (`translateY(${0.2*(50 - percentage * (Math.sqrt(percentage)/10))}%)`)
    }
    const handleHover = (e) => {
        const coords = mouseMoveCoords(e, {
            round : true
        });
        setY(computeCoordinates(coords.y));
        setTrd("0s")
    }
    const handleLeave = () => {
        setTrd(".5s");
        setY("none")
    }
    const close = () => {
        props.callback(null);
    }
    const preventPropagation = (e) => {
        e.stopPropagation();
    }
    useEffect(()=>{
        if(!props.cell){
            setTimeout(() => {
                setContent(null);
            }, 500);
        } else {
            setContent(props.cell);
        }
    },[props.cell])
    return(
        <div ref={props.ref} className={`modal ${props.cell && "in" || ""}`} onClick={close}>
            {
                <div className="content" onMouseMove={handleHover} onMouseLeave={handleLeave} onClick={preventPropagation}> 
                <div className="title">
                    {content?.title}
                </div>
                <div className="text">
                    {content?.text}
                </div>
                <div className="img">
                    <div className="container" style={{
                            transitionDuration : {trd},
                            transform : y
                        }}>
                        <img src={content?.img} alt="" srcset="" />
                    </div>
                </div>
                <div className="close" onClick={close}>

                </div>
            </div>
            }
        </div>
    )
}