const Text = (props) => {
    return(
        <div className="text" style={{
            marginBottom : props.margin ? props.margin : ""
        }}>
            {props.children}
        </div>
    )
}

export default Text;