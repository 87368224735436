import { useEffect, useLayoutEffect, useState } from "react"
import React from "react";

export default function SvgTrick(props){
    const [dom, setDom] = useState();
    const init = (path) => {
        path.style.transitionDuration = props.trd;
        const length = path.getTotalLength();
        path.style.strokeDasharray = `${length} ${length}`
        path.style.strokeDashoffset = length * (1-props.animationRatio)
    }
    useLayoutEffect(()=>{
        dom && (()=>{
            Array.from(dom.querySelectorAll("path")).forEach((p)=>{
                init(p)
            })
        })();
    },[dom, props.animationRatio])
    return(
        React.cloneElement(props.children, {domProvider: setDom})
    )
}