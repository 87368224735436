import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import focus from "./assets/temp-logo-focus.ico"
import blur from "./assets/temp-logo-unfocus.ico"
import handleUrlError from "./handleUrlErrors"

const errors = handleUrlError();

document.title = "canToo"

const setFavicon = (ico) => {
  document.querySelector("link[rel~='icon']").href = ico
}
window.addEventListener('blur', ()=>{setFavicon(blur)});
window.addEventListener('focus', ()=>{setFavicon(focus)}); 



ReactDOM.render(
  <React.StrictMode>
    <App errors={errors}/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
