import { useEffect } from "react"

const useBoundingClientRect = (ref, callback) =>{
    const executeCallback = () => {
        ref.current && callback && callback(ref.current.getBoundingClientRect());
    }
    return useEffect(()=>{
        if(ref.current){
            window.addEventListener("scroll", executeCallback);
        }
        return()=>window.removeEventListener("scroll", executeCallback);
    }, [ref])
}

export default useBoundingClientRect;