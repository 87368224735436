const RotatingCantoo = () =>{
    return(
        <span className="rotating-cantoo">
            <span className="can-wrap">
                <span className="can">
                    can
                </span>
            </span>
            <span className="too-wrap">
                <span className="too">
                    Too
                </span>
            </span>
        </span>
    )
}

export default RotatingCantoo;