import vh from "./vh";
import vw from "./vw"

export default function mouseMoveCoords (e, flags){
    const x = () => {
        const el = e.currentTarget;
        const width = el.offsetWidth;
        const windowWidth = vw(100, false);
        const offset = (windowWidth-width) / 2;
        const cursorWidth = e.clientX;
        return(
            flags.round
            &&
            Math.round((cursorWidth-offset) / width * 100)
            ||
            (cursorWidth-offset) / width * 100
        );
    }
    const y = () => {
        const el = e.currentTarget;
        const height = el.offsetHeight;
        const windowHeight = vh(100, false);
        const offset = (windowHeight-height) / 2;
        const cursorHeight = e.clientY;
        return(
            flags.round
            &&
            Math.round((cursorHeight-offset) / height * 100)
            ||
            (cursorHeight-offset) / height * 100
        );
    }
    return({
        x : x(),
        y : y()
    })
}