import { useRef, useEffect, useState } from "react"
import Can from "../pc"
import useAnimationRatio from "../../functions/useAnimationRatio";
import useAnimationRatioCallbacks from "../../functions/useAnimationRatioCallbacks";
import IntersectionObserved from "../intersectionObserved";
import vh from "../../functions/vh";
import Halftone from "./halftone";
export default function Work(){
    const ref = useRef(null);
    const containerRef = useRef(null)
    const [ht, setHt] = useState(false);
    const [visible, setVisible] = useState(true);
    const [cursorRatio, setCursorRatio] = useState([0,0])
    const triggerHt = () =>{
        setHt(true);
        setTimeout(()=>{
            setHt(false)
        }, 500)
    }
    const [progress, keys, current] = useAnimationRatio(ref)
    const [cb1] = useAnimationRatioCallbacks([
        {
            triggerRatio : 0.95,
            callback : triggerHt,
            params : {
                direction : true
            }
        }
    ]);
    const [progress2, keys2, current2] = useAnimationRatio(ref, vh(100, false), cb1)
    const handleMove = (e)=>{
        if(containerRef.current){
            const width = containerRef.current.offsetWidth;
            const height = containerRef.current.offsetHeight;
            setCursorRatio([
                (Math.sqrt(e.clientX/width)-0.5) * 2,
                (Math.sqrt(e.clientY/height)-0.5) * 2
            ])            
        }
    }
    return(
        <IntersectionObserved callback={setVisible}>
            <div className="work" ref={ref}>
                <div className="speech">
                    <div className="text">
                    {
                        progress === 0 ?
                        "d'un PC"
                        :
                        "Pour travailler, j'ai besoin :"
                        
                        }
                    </div>
                    
                </div>
                <div className="td-container" onMouseMove={handleMove} ref={containerRef}>
                    <Can rotationFactor={progress} visible={visible} secondAnimation={progress2} cursorRatio={cursorRatio}></Can>
                </div>
                <Halftone dotsColor={"#D2664B"} triggered={ht}></Halftone>
            </div>
        </IntersectionObserved>
    )
}