import Li from "./li"

const IntersectionObservedList = (props) => {
    return(
        <ul>
            {
                props.li.map(li => (
                    <Li>
                        {li}
                    </Li>
                ))
            }
        </ul>
    )
}

export default IntersectionObservedList