import SoftsContainer from "./softs-container";
import softs from "../../../constants/softs"


export default function SoftsList (props) {
    return (
        <SoftsContainer n={props.scrollRatio} setN={props.setN} softs={softs}>
            {
                props.softs.map(soft => (
                    <img src={soft.img}/>
                ))
            }

        </SoftsContainer>
    )
}
