import Frames from "../../components/frames"
import Speech from "../../components/speech"
import Work from "../../components/work"
import Uses from "../../components/uses"
import IntersectionObservedList from "../../components/intersectionObservedList"
import DogsPath from "../../components/dogsPath"
import { useEffect, useState } from "react"
import Text from "../../components/text"
import Contacts from "../contacts"
import RotatingCantoo from "../../components/rotatingCantoo"
import Footer from "../../components/footer"
import Cta from "../../components/cta"
import P from "../../components/p"
import SoftSkills from "../../components/softSkills"

import NewTab from "../../components/newTab"
import Ruler from "../../components/icons/ruler"
import Comunicate from "../../components/icons/comunicate"
import Brain from "../../components/icons/brain"
import Flex from "../../components/icons/flex"
export default function Index () {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
          });
    }, [])
    return(
        <div className="home-page">
            
            <Frames/>
            <Text>
                <h2>Mon parcours</h2>
                <P>
                    Après avoir obtenu mon bac S mention assez bien, j'ai choisi de m'orienter vers des modeles d'apprentissage correspondant mieux a mon mode de fonctionnement.
                </P>
                <P>
                    Ainsi, après des formations en ligne et de nombreuses recherches, j’ai découvert THP : The Hacking Project. Cette formation en ligne correspondait parfaitement à ma vision de l’apprentissage. Au cours de ces huit mois, j’ai pu travailler en équipe sur des projets full stack, en suivant la méthode agile, en adoptant le design atomique et l’architecture MVC.
                </P>
                <P>
                    Et ma maîtrise s’est considérablement améliorée. Mais même avec ce nouveau savoir, j’ai continué à peaufiner mon savoir-faire. J’ai travaillé : j’ai élaboré des travaux pratiques, j’ai fait de la veille technologique et me suis lancé des défis.
                </P>
                <P>
                    Outre ces acquis indispensables, 
                </P>

            </Text>
            <Speech/>
            <Text margin={"-15vh"}>

                <h2>Et maintenant?</h2>
                <P>
                    Mais j’étais face à un problème : mon profil était noyé parmis des milliers. Au delà de mes 4 ans d'expérience, de veille et de recherches sur des sujets gravitant autour de la programmation, je savais que je devais faire mes preuves. Alors j’ai fait ce site où j’ai pu m'initier au metier de :
                </P>

                <IntersectionObservedList li={
                        [
                            "Graphiste",
                            "Designer",
                            "Copywriter",
                            "Modélisateur 3D"
                        ]
                }/>
            </Text>
            <Work/>
            <Uses/>
            <Text>
                <h2>Mais cela ne fait pas tout</h2>
                <P>
                    J’ai l’habitude de dire que les softs skills représentent 45% du travail.
                </P>
                <P>
                    J’ai donc défini ces derniers et travaillé pour devenir le collegue que je voulais avoir.
                </P>
            </Text>
            <SoftSkills>
                {
                    [
                        {
                            icon : <Comunicate/>,
                            title : "Communiquer",
                            text : `Pour mener un projet à bien, il est important d'écouter et de s’exprimer clairement. Il est vrai que l’intelligence sociale est un prérequis. Mais tout aussi important, la communication formelle : un moyen de transmission des informations de façon bien établie  et transparente indispensable en entreprise.`
                        },
                        {
                            icon : <Flex/>,
                            title : "Être flexible",
                            text : `Pour être flexible, il ne suffit pas d’apprendre de nouvelles technologies ou architectures de projets. Il faut être ouvert d’esprit, écouter les critiques et chercher à produire un code le plus scalable possible afin de se confronter au plus de problématiques possible qui deviennent plus que des obstacles : des opportunités d’apprendre.`
                        },
                        {
                            icon : <Brain/>,
                            title : "Être créatif",
                            text : [
                                `Ce n'est pas parce que le métier de développeur est un métier technique qu’il est dépourvu de créativité. Ma définition de la créativité est : “la capacité de repérer, analyser, transformer ou casser des motifs récurrents pour résoudre un problème. Et ce, peu importe le degré de proximité entre les domaines concernés". De plus, ma condition d’autodidacte m’a permis d’adopter une mentalité de hacker, `,
                                <NewTab href="https://coreight.com/content/esprit-hacker">en savoir plus sur l'esprit hacker</NewTab>,
                                `. Merci à `,
                                <NewTab href="https://fr.wikipedia.org/wiki/Srinivasa_Ramanujan">Srinivasa Ramanujan</NewTab>,
                                " et ",
                                <NewTab href="https://fr.wikipedia.org/wiki/Grigori_Perelman">Grigory Perelman</NewTab>,
                                ` pour ce précieux enseignement.`
                            ]
                        },
                        {
                            icon : <Ruler/>,
                            title : "Discipline",
                            text : `Être discipliné et créatif ne sont pas des notions antinomiques. J’en veux pour preuve les cuisiniers, les réalisateurs et les orchestres. J’estime que la discipline est un outil pour pouvoir exprimer pleinement sa créativité. `
                        }
                    ]
                }
            </SoftSkills>
            <Text>
                <h2>Conclusion</h2>
            
                <P>
                    J'espère sincèrement que cette présentation vous aura convaincu.
                </P>
                <P>
                    Et que vous comprendrez la raison d'être de ce site.
                </P>
                <P>
                    Mais surtout l’origine de mon pseudo : <RotatingCantoo/>
                </P>
            <h2>
                Vous pouvez me contacter
            </h2>

            <Contacts/>

            vous pouvez avoir une présentation plus concise et factuelle de mon parcours : <br/>

            <Cta link="/cv">Voir mon CV</Cta>

            </Text>
            <Footer></Footer>
        </div>
    )
}