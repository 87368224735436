import Outline from "./outline"

const Contact = (props) => {
    return(
        <div className="contact">
            <div className="content">
                <div className="icone">
                    {props.icon}
                </div>
                <div className="text">
                    <a href={`${props.mail && 'mailto:' || ""}${props.href}`}>
                        {
                            props.children
                        }
                    </a>
                </div>
            </div>
            <Outline strokeWidth={"5px"} gradient={props.gradient}/>
        </div>
    )
}
export default Contact