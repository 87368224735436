
import { Canvas} from "@react-three/fiber"
import { Suspense, useState } from "react"
import vh from "../../functions/vh"
import vw from "../../functions/vw"
import Laptop from "../../components/3dCan/Laptop"
export default function Can (props) {
    const [rotation, setRotation] = useState({
        x : 0,
        y : 0,
        z : 0
    })
    const handleMove = (e) => {
        const wvh = vh(100, false)
        const wvw = vw(100, false)
        const rotationX = ((e.clientX / wvh) / 2) - (e.clientX / wvh) * 20
        const rotationY = ((e.clientX / wvw) / 2) - (e.clientX / wvw) * 20
        const rotationZ = ((((e.clientX / wvh) / 2) - (e.clientX / wvh)) * (((e.clientY / wvw) / 2) - (e.clientY / wvw))) * 100
        setRotation({
            x : 0,
            y : Math.round(Math.PI / rotationY * 100)/100,
            z : Math.round(Math.PI / rotationZ * 100)/100
        })
    }
    return (
        <div  onMouseMove={handleMove} className="pc">
            <Canvas
            gl={{ antialias: true }}
            color
            shadows
            camera={{
                position : [
                    0,
                    4,
                    8
                ],
                fov : 20
            }
            }
            >
                <Suspense fallback={null}>
                    <Laptop rotationFactor={props.rotationFactor} visible={props.visible} secondAnimation={props.secondAnimation} 
                    cursorRatio={props.cursorRatio}/>
                    {/* <Model rotation={rotation}/> */}
                </Suspense>
                <pointLight color="white" intensity={1} position={[0, 0, 10]} />
                <pointLight color="white" intensity={0.2} position={[100, 0, 10]} />
                <pointLight color="white" intensity={0.2} position={[-100, 0, 10]} />
                <pointLight color="white" intensity={0.2} position={[0 ,100, 10]} />
            </Canvas>
        </div>
    )
}