import IntersectionObserved from "../../intersectionObserved"
import vh from "../../../functions/vh"
import { useState } from "react"

const IntersectionObservedText = (props) => {
    const [inView, setInview] = useState(false)
    const rootMargin = props.rootMargin ? props.rootMargin : vh(-10);
    return(

        <IntersectionObserved rootMargin={props.rootMargin} callback={setInview}> 
            <div className={
                `${props.className} ${inView && props.inViewClassName}`
            }>
                {props.children}
            </div>
        </IntersectionObserved>
    )
}

export default IntersectionObservedText;