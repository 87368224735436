import { useEffect, useLayoutEffect, useRef, useState } from "react";
import SvgTrick from "./svgTrick"
export default function Tile(props){
    const [animationRatio, setAnimationRatio] = useState(0);
    const animate = (n) =>{
        setAnimationRatio(n)
    }
    const ref = useRef(null);
    useLayoutEffect(()=>{
        props.heightCallback(ref.current.offsetHeight)
    })
    useEffect(()=>{
        props.current % props.length === props.index ?
        animate(1) :
        animate(0)
    }, [props.current])
    return(
        <div className="tile" style={{
            height : props.height
        }}>
            <div className="icon"
            style={{
                transform : `translateX(${(animationRatio-1) * 50 }vw)`,
                opacity : animationRatio,
                height : `${props.height}px`
            }}>
                {
                    <SvgTrick animationRatio={animationRatio} trd="1.5s">
                        {props.icon}
                    </SvgTrick>
                }
            </div>
            <div className="text" ref={ref}
            style={{
                transform : `translateX(${-1*(animationRatio-1) * 50 }vw)`,
                opacity : animationRatio
            }}>
                {props.children}
            </div>
        </div>
    )
}