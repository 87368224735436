const languages = {
 html : `
 <html>
     <body>
         <h1>
             Voici ce a quoi ressemble le HTML
         </h1>
         <p>
             un site tel quel serait moche mais c'est la base de (quasiment) toutes les pages web
         </p>
     </body>
 </html>
 `,
 c:`
 // voici un programme qui additionne deux entiers 
 #include<iostream>

 int add(int x, int y)
 {
     return(x+y);
 }

 int main()
 {
     using namespace std;
     int a,b;
     cout<<"Entrer le premier nombre: ";
     cin>>a;
     cout<<"Entrer le second nombre: ";
     cin>>b;
     cout<< a << "+" << b << " = "<<add(a,b);
 }
 `,
 rails:`
 #donne un rabais de 10 dollars a tous les utilisateurs
 discount = Discount.new(amount: 10, currency: "dollars")
 User.all.each do |user|
     user.give_discount(discount)
 end
 `,
 css:`
 /*  les imgages remplissent maintenant
     leurs conteneurs dans le HTML
     en preservant leurs ratio d'affichage */
 img{
     max-width : 100%;
     max-height : 100%;
 }
 `,
 ruby : `
 # voici un programme qui additionne deux nombres 
 def add(x, y)
     return x + y
 end
 puts "Entrer le premier nombre : "
 num1 = gets.chomp.to_i
 puts "Entrer le second nombre: "
 num2 = gets.chomp.to_i
 puts "#{num1} + #{num2} = #{add(num1, num2)}"
 `,
 react : ` 
 // voici un element react qui compte les secondes
 import {useState, useEffect} from "react"
 export default function stopwatch() {
 const [seconds, setSeconds] = React.useState(0);

 React.useEffect(() => {
     setTimeout(() => setSeconds(seconds + 1), 1000);
 });

 return (
     <div className="App">
     <div>
         {seconds}
     </div>
     </div>
 );
 }`,
 js : `
 // voici un programme qui additionne deux nombres 
 function add(x, y){
     return(x + y);
 }
 const num1 = window.prompt("veuillez entrer un nombre");
 const num2 = window.prompt("et un autre");
 window.alert(${"`"}${"$"}{num1} + ${"$"}{num2} = ${"$"}{add(num1, num2)}${"`"}) ;
 `,
 php : `
 <?php
 function add($x, $y){
     return($x + y);
 }

 echo add(2, 3);

 }
 ?>
 `
}

export default languages;