export default function PrinterModal (props) {
    return(
        <div className="printer-modal" onClick={props.handleLeave}>
            <div className="modal">
                <h2>
                    Bon a savoir !
                </h2>
                <p>
                    Ce CV est imprimable tel-quel.
                </p>
                <div className="cross" on></div>
            </div>
        </div>
    )
}