import IntersectionObserved from "../../intersectionObserved";
import { useState, useRef } from "react";
import Cursor from "./cursor";
import vh from "../../../functions/vh";
export default function Frame (props) {
    return(
        <div className="section">
                <div className={`img ${!props.iframe && "a"}`}>
                    {
                        props.iframe ? /* CONDITIONAL */
                        <iframe srcDoc={props.url} frameborder="0" className="img"/>
                        :
                        <img src={props.url} alt="" srcset="" />
                    }
                </div>
                {
                    props.children && /* CONDITIONAL */
                    <div className="texts">
                        {
                            props.children
                        }
                    </div>
                }
                {props.otherUrl && <Cursor size={vh(10, false)} url={props.otherUrl}/>}

        </div>
    )
}