import Envelope from "../../components/icons/envelope";
import LinkedIn from "../../components/icons/linkedin";
import Css from "../../components/icons/css";
import Html from "../../components/icons/html";
import Js from "../../components/icons/js";
import ReactIcon from "../../components/icons/react";
import Rails from "../../components/icons/rails";
import ScrewDriverNWrench from "../../components/icons/screwDriverNWrench";
import Languages from "../../components/icons/languages";
import Book from "../../components/icons/book";
import Cube from "../../components/icons/cube";
import Robot from "../../components/icons/robot";
import Brain from "../../components/icons/brain";
import Maths from "../../components/icons/maths";
import Iconable from "./iconable";
import AnotedText from "./anotedText";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import PrinterModal from "./printerModal";
import { Link } from "react-router-dom";
const Cv = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['modal-seen']);
    const [modalSeen, setModalSeen] = useState(true);
    useEffect(()=>{
        if(cookies['modal-seen'] == 1){
            setModalSeen(true);
        } else {
            setModalSeen(false)
        }
    }, [cookies])
    const handleLeave = () => {
        setCookie('modal-seen', 1);
        setModalSeen(true);
    }

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
          });
    }, [])
    return(
        <>
            {
                !modalSeen
                &&
                <PrinterModal handleLeave={handleLeave}/>
            }
            <div className="cv">
                <div className="aside">
                    <div className="header desktop">
                        <div className="title">
                            Boris Marion-Dorier
                        </div>
                        <div className="sub">
                            Développeur Front-End / Back-End 
                        </div>
                    </div>
                    <div className="lists">
                        <div className="contact">
                            <Iconable icon={<Envelope/>}>contact@cantoo.dev</Iconable>
                            <Iconable icon={<LinkedIn/>}>Boris Marion-Dorier</Iconable>
                        </div>
                        <h2>
                            <Iconable icon={<ScrewDriverNWrench/>}>Languages, Librairies et Frameworks</Iconable>
                        </h2>
                            <ul>
                                <li>
                                    <AnotedText source="Wikipedia" anotation={" Le HyperText Markup Language, généralement abrégé HTML ou dans sa dernière version HTML5, est le langage de balisage conçu pour représenter les pages web."}>
                                    HTML 5</AnotedText>
                                    <div className="marker"><Html/></div>
                                </li>
                                <li>
                                    <AnotedText source="Wikipedia" anotation={" Les feuilles de style en cascade, généralement appelées CSS de l'anglais Cascading Style Sheets, forment un langage informatique qui décrit la présentation des documents HTML."}>
                                        CSS 3 
                                    </AnotedText>
                                    <div className="marker"><Css/></div>
                                </li>
                                <li>
                                    <AnotedText source="Wikipedia" anotation={"JavaScript est un langage de programmation de scripts principalement employé dans les pages web interactives et à ce titre est une partie essentielle des applications web. Avec les technologies HTML et CSS, JavaScript est parfois considéré comme l'une des technologies cœur du World Wide Web. "}>
                                        JavaScript
                                    </AnotedText>
                                    <div className="marker"><Js/></div>
                                </li>
                                <li>
                                    <AnotedText source="Wikipedia" anotation={"React est une bibliothèque JavaScript libre développée par Facebook depuis 2013. Le but principal de cette bibliothèque est de faciliter la création d'application web monopage, via la création de composants dépendant d'un état et générant une page HTML à chaque changement d'état. "}>
                                        React
                                    </AnotedText>
                                    <div className="marker"><ReactIcon/></div>
                                </li>
                                <li>
                                    <AnotedText anotation="Ruby on Rails est un framework (un cadre de travail) qui permet de faire des sites complets ou des APIs (Interfaces de programation d'Applications) permettant ainsi d'interagir avec des bases de donnees, faire du traitement complexe de donnees ou gerer l'authentification des usagers ">
                                        Ruby on Rails
                                    </AnotedText>
                                    <div className="marker"><Rails/></div>
                                </li>
                            </ul>
                            <h2>
                                <Iconable icon={<Languages/>}> Langues </Iconable>
                            </h2>
                            <ul>
                                <li>Francais (Natif)</li>
                                <li>Anglais (Avance)</li>
                                <li>Espagnol (Notions)</li>
                                <li>Lorem Ipsum</li>
                            </ul>
                            <h2>
                                <span className="iconable">
                                    Centres d'interet <div className="icon"><Book/></div>
                                </span>
                            </h2>
                            <ul>
                                <li>
                                    <AnotedText anotation="L’impression 3D est un domaine très vaste qui me permet d’adopter une nouvelle façon de penser et de raisonner.">
                                        Impression 3D
                                    </AnotedText>
                                    <div className="marker"><Cube/></div>
                                </li>
                                <li>
                                    <AnotedText anotation="Ayant commencé à coder avec le C++, travailler avec Arduino est l'équivalent de la madeleine de Proust pour moi.">
                                        Arduino et I.o.T
                                    </AnotedText>
                                    <div className="marker"><Robot/></div>
                                </li>
                                <li>Psychologie sociale et hacking social <div className="marker"><Brain/></div></li>
                                <li>Mathematiques et sciences dures <div className="marker"><Maths/></div></li>
                            </ul>
                    </div>
                </div>
                <div className="main">
                    <div className="header mobile">
                        <div className="title">
                            Boris Marion-Dorier
                        </div>
                        <div className="sub">
                            Développeur Front-End / Back-End 
                        </div>
                    </div>
                    <p>
                        Après mon Baccalauréat, j'ai tenté la piscine de l'école 42. Même si je n’ai pas été retenu, cette expérience m'a tout de même permis de me mettre sérieusement à la programmation, me donnant la rigueur et une nouvelle façon d'appréhender les problèmes. Après un an et demi de HTML/CSS/JavaScript je me suis mis au Back-End en PHP. Mais ma courbe de progression a commencé à stagner. J'ai donc décidé d'arrêter mon parcours d'autodidacte pour m'inscrire à The Hacking Project qui m'a donné une opportunité de progression dans mon apprentissage en y posant des jalons. J'y ai appris à: 
                    </p>
                    <ul>
                        <li>
                            Travailler en équipe
                        </li>
                        <li>
                            Abandonner mon désir de tout faire moi-même au profit de l'efficacité
                        </li>
                        <li>
                            Comprendre l'importance des librairies et Framorks
                        </li>
                        <li>
                            Concevoir l’architecture d’un projet web ou applicatif
                        </li>
                        <li>
                            Intervenir sur le front-end de l’application ou du site Internet
                        </li>
                        <li>
                            Intervenir sur le développement du back-end
                        </li>
                        <li>
                            Configurer et maintenir les infrastructures d’hébergement
                        </li>
                        <li>
                            Concevoir et structurer une base de donnée grâce à un ORM   
                        </li>
                        <li>
                            Maintenir le site ou l’application.
                        </li>

                    </ul>
                    <p>
                        Je suis curieux et entretiens sans même m'en rendre compte ma veille technologique. Grâce à mon parcours et ma personalité atytpique, j'ai su engranger et mettre en relations des domaines et compétences variés. Cela me confère autonomie et adaptabilité.             
                    </p>
                    <p>
                        Formations :
                    </p>
                    <ul>
                        <li>
                            Développeur Full-Stack
                        </li>
                        <li>
                            Développeur Front-End avec React
                            <ul>
                                <li>
                                    The Hacking Project (Alumni)
                                </li>
                            </ul>
                        </li>
                        <li>
                            Baccalauréat Scientifique (Mention assez bien)
                            <ul>
                                <li>
                                    Lycée Maréchal Soult, Mazamet
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <Link to="/">
                    <div className="prev">
                        retour
                    </div>
                </Link>
            </div>
        </>
    )
}

export default Cv;