import drawnToocan from "../../assets/draw_toocan_v2.png";
import drawnToocanSkull from "../../assets/draw_toocan_skull.png";
import toocan from "../../assets/toocan.png"
import { useRef} from "react";
import Frame from "./frame";
import Lengends from "./legends";
import toocanIframeContent from "../toocanIframeContent";
import IntersectionObservedText from "./intersectionObservedText";
import CountDownText from "./countDownText";
export default function Frames (){
    const ref = useRef(null);
    const executeScroll = () => ref.current.scrollIntoView();
    
    return(
        <div className="frames">
            <Frame url={toocanIframeContent} iframe>
                <div>
                    <div className="brand">
                    (H)i, canToo
                    </div>
                </div>
                <div ref={ref}>
                    <div className="flex">
                        <IntersectionObservedText className={"content walk"} inViewClassName="in">
                            Bonjour ! C’est Boris A.k.a canToo
                        </IntersectionObservedText>
                        <IntersectionObservedText className={"content walk"} inViewClassName="in">
                            Je vais me présenter :
                        </IntersectionObservedText>
                        <CountDownText>
                            Tâchons d'être concis
                        </CountDownText>
                    </div>
                </div>
            </Frame>
            <Frame url={drawnToocan}>
                <div>
                    <Lengends legends={
                        [
                            {
                                top : 25,
                                left : 34.2,
                                text : "Repérer les bugs",
                                content : "Le toucan a unne bonne vue !"
                            },
                            {
                                top : 49,
                                left : 62,
                                text : "Utiliser des outils",
                                content : "Grace a son bec"
                            },
                            {
                                top : 62,
                                left : 29,
                                text : "Son ramage se raporte à son plumage"
                            }
                        ]
                    }/>
                </div>
                <div>
                    <div className="flex">
                        <IntersectionObservedText className={"content anyway"} inViewClassName="in">
                            Je suis développeur Front-end
                        </IntersectionObservedText>
                        <IntersectionObservedText className={"content anyway"} inViewClassName="in">
                            Back-end
                        </IntersectionObservedText>
                        <CountDownText>
                            Bref, je suis Full-stack
                        </CountDownText>
                    </div>
                </div>
            </Frame>
            <Frame url={drawnToocanSkull} otherUrl={toocan}>
                <div>
                    <Lengends legends={
                        [
                            {
                                top : 18.5,
                                left : 28,
                                text : "Travailler en équipe"
                            },
                            {
                                top : 37,
                                left : 44,
                                text : "Communiquer",
                                content : "Pour un projet plus stable"
                            },
                            {
                                top : 62,
                                left : 29,
                                text : ".plumage { display : none }",
                                content : "Pour optimiser les performances"
                            }
                        ]
                    }/>
                </div>
                <div>
                    <div className="flex">
                        <IntersectionObservedText className={"content anyway"} inViewClassName="in">
                            Parce qu'un dessin
                        </IntersectionObservedText>          
                        <IntersectionObservedText className={"content anyway"} inViewClassName="in">
                            Ne vaut pas mille mots
                        </IntersectionObservedText>
                        <CountDownText>
                            Laissez-moi me présenter :
                        </CountDownText>
                    </div>
                </div>
            </Frame>
            <div className="scrolldown" onClick={executeScroll}></div>
        </div>
    )
}